// src/components/CreatePoll.js

import React, { useState } from 'react';

const CreatePoll = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [options, setOptions] = useState(['', '', '']);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://pratomm.com/polls/polls.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ title, description, options }),
        });

        if (response.ok) {
            alert('Poll created successfully!');
            setTitle('');
            setDescription('');
            setOptions(['', '', '']);
        } else {
            alert('Failed to create poll.');
        }
    };

    const handleOptionChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const addOption = () => {
        setOptions([...options, '']);
    };

    const removeOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Create Poll</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Title</label>
                    <input
                        type="text"
                        className="border p-2 rounded w-full"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Description</label>
                    <textarea
                        className="border p-2 rounded w-full"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 mb-2">Options</label>
                    {options.map((option, index) => (
                        <div key={index} className="flex mb-2">
                            <input
                                type="text"
                                className="border p-2 rounded w-full"
                                value={option}
                                onChange={(e) => handleOptionChange(index, e.target.value)}
                                required
                            />
                            {options.length > 2 && (
                                <button
                                    type="button"
                                    onClick={() => removeOption(index)}
                                    className="ml-2 text-red-500 hover:underline"
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addOption}
                        className="text-blue-500 hover:underline"
                    >
                        Add Option
                    </button>
                </div>
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">
                    Create Poll
                </button>
            </form>
        </div>
    );
};

export default CreatePoll;
