import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PollDetail = () => {
    const { id } = useParams();
    const [poll, setPoll] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [hasVoted, setHasVoted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(`https://pratomm.com/polls/polls.php?id=${id}`)
            .then(response => response.json())
            .then(data => {
                setPoll(data);
                setIsLoading(false);

                // Check if the user has already voted
                const storedVote = localStorage.getItem(`poll_${id}`);
                if (storedVote) {
                    setHasVoted(true);
                    setSelectedOption(parseInt(storedVote, 10));
                }
            });
    }, [id]);

    const handleVote = async (optionId) => {
        if (hasVoted) return; // Prevent multiple votes

        setSelectedOption(optionId);
        setHasVoted(true);

        // Store the vote in localStorage to prevent multiple votes
        localStorage.setItem(`poll_${id}`, optionId);

        try {
            const response = await fetch(`https://pratomm.com/polls/votes.php?poll_id=${id}&option_id=${optionId}`, {
                method: 'POST',
            });

            if (response.ok) {
                // Fetch the poll results after voting
                fetch(`https://pratomm.com/polls/polls.php?id=${id}`)
                    .then(response => response.json())
                    .then(data => {
                        setPoll(data);
                    });
            } else {
                console.error('Failed to submit vote.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (isLoading || !poll) {
        return <div className="text-center mt-4"><box-icon name='loader' animation='spin'></box-icon> Loading...</div>;
    }

    const totalVotes = poll.options.reduce((sum, option) => sum + option.votes, 0);
    const pageUrl = `https://poll.ygn.me/${id}`;
    const imageUrl = `https://www.pixelliongroup.com/poll/preview.php?t=${encodeURIComponent(poll.title)}`;

    return (
        <div className="p-8 max-w-xl mx-auto">
            <Helmet>
                <title>{poll.title}</title>
                <meta name="title" content={poll.title} />
                <meta name="description" content="Click to vote and see what others think too. Sharing means more votes and more fun seeing the results together!" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:title" content={`${poll.title} Click to vote!`} />
                <meta property="og:description" content="Click to vote and see what others think too. Sharing means more votes and more fun seeing the results together!" />
                <meta property="og:image" content={imageUrl} />

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={pageUrl} />
                <meta property="twitter:title" content={`${poll.title} Click to vote!`} />
                <meta property="twitter:description" content="Click to vote and see what others think too. Sharing means more votes and more fun seeing the results together!" />
                <meta property="twitter:image" content={imageUrl} />
            </Helmet>
			
            <h1 className="text-xl mb-6 text-gray-900">{poll.title}</h1>
            <div className="space-y-3">
                {poll.options.map((option, index) => (
                    <button
                        key={option.id}
                        onClick={() => handleVote(option.id)}
                        className={`cursor-pointer w-full py-3 px-6 text-lg font-medium rounded-lg transition-colors duration-300 ${
                            selectedOption === option.id ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-800 hover:bg-blue-500 hover:text-white'
                        }`}
                        disabled={hasVoted}
                    >
                        {option.option_text}
                    </button>
                ))}
            </div>
            {hasVoted && (
                <div className="mt-8">
                    <h2 className="text-xl text-black mb-4">Poll Results</h2>
                    <ul className="space-y-4">
                        {poll.options.map(option => {
                            const percentage = totalVotes > 0 ? (option.votes / totalVotes) * 100 : 0;
                            return (
                                <li key={option.id} className="space-y-1">
                                    <div className="flex justify-between items-center text-gray-800">
                                        <span>{option.option_text}</span>
                                        <span className="text-sm text-gray-600">{Math.round(percentage)}%</span>
                                    </div>
                                    <div className="w-full bg-gray-300 rounded-full h-3">
                                        <div
                                            className="bg-blue-600 h-3 rounded-full"
                                            style={{ width: `${percentage}%` }}
                                        ></div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <h2 className="text-xs my-5 text-center text-gray-500">Poll by Pixelsaim ©</h2>
        </div>
    );
};

export default PollDetail;
