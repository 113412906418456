// src/components/PollList.js

import React, { useState, useEffect } from 'react';

const PollList = () => {
    const [polls, setPolls] = useState([]);

    useEffect(() => {
        fetch('https://pratomm.com/polls/polls.php')
            .then(response => response.json())
            .then(data => setPolls(data));
    }, []);

    return (
        <div className="p-4">
            <h1 className="text-xl uppercase mb-10">Polls</h1>
            <ul>
                {polls.map(poll => (
                    <li key={poll.id} className="mb-2 px-5 py-5 border rounded">
                        <a href={`/${poll.id}`} className="text-black">
                            {poll.title}
                        </a>
                    </li>
                ))}
            </ul>
            <h2 className="text-xs my-5 text-center text-gray-500">Poll by Pixelsaim ©</h2>
        </div>
    );
};

export default PollList;
