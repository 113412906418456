// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PollList from './components/PollList';
import CreatePoll from './components/CreatePoll';
import PollDetail from './components/PollDetail';

function App() {
    return (
        <Router>
            <div className="container mx-auto p-4">
                
                <Routes>
                    <Route path="/" element={<PollList />} />
                    <Route path="/create" element={<CreatePoll />} />
                    <Route path="/:id" element={<PollDetail />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
